import React, {FC, PropsWithChildren, useEffect, useRef, useState} from 'react';
import './App.css';

import axios from 'axios';

import {createWeb3Modal} from '@web3modal/wagmi/react';
import {defaultWagmiConfig} from '@web3modal/wagmi/react/config';
import {WagmiProvider} from 'wagmi';
import {getAccount, http} from '@wagmi/core';
import {bsc, bscTestnet} from 'wagmi/chains';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

export const App: FC = () => {
    const queryClient = new QueryClient()
    const projectId = 'ab1241db61d908d016cd72afe912ace1'
    const metadata = {
        name: 'Web3Modal',
        description: 'Cloud•Hareverse',
        url: 'https://0xhares.io',
        icons: ['https://avatars.githubusercontent.com/u/37784886']
    };
    const chains = [bsc, bscTestnet] as const
    const config = defaultWagmiConfig({
        chains: chains,
        transports: {
            [bsc.id]: http(),
            [bscTestnet.id]: http(),
        },
        projectId: projectId,
        metadata: metadata,
    });

    createWeb3Modal({
        wagmiConfig: config,
        projectId,
        enableAnalytics: true,
        enableOnramp: true
    });

    /*登录类型*/
    enum LoginType {
        None = 0,
        Wallet = 1,
        Email = 2
    }

    enum LanguageType {
        English,
        ChineseTraditional,
    }

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [isShowCloudGame, setIsShowCloudGame] = useState(false);
    const [cloudGameUrl, setCloudGameUrl] = useState("");

    let gameUrl = "";
    let isFirstStartup: boolean = false;
    let loginType = LoginType.None;
    let isWallet = false;
    let isEnglish = true;
    let language = LanguageType.English;
    let address = "";
    let isSend = false;

    const showCloudGame = (): void => {
        if (!isFirstStartup) {
            isFirstStartup = true;
            // 获取配置文件
            fetch('config.json').then(response => response.json()).then(configData => {
                let configUrl;
                if (configData.IsHttps) {
                    configUrl = 'https://' + configData.Ip + ':' + configData.Port + configData.Router + "?language=" + language;
                } else {
                    configUrl = 'http://' + configData.Ip + ':' + configData.Port + configData.Router + "?language=" + language;
                }

                axios.get(configUrl).then(response => {
                    // 获取云服务器的url
                    if (configData.IsHttps) {
                        gameUrl = 'https://' + response.data.Ip + ':' + response.data.Port;
                    } else {
                        gameUrl = 'http://' + response.data.Ip + ':' + response.data.Port;
                    }

                    setCloudGameUrl(gameUrl);
                }).catch(error => {
                    console.error('There was an error fetching the data:', error);
                });

            }).catch(error => {
                console.error('Error:', error);
            });
        }
    };

    /*向子页面发送消息*/
    function sendMessage(json: string) {
        if (iframeRef.current && iframeRef.current.contentWindow != null) {
            iframeRef.current.contentWindow.postMessage(json, '*');
        }
    }

    useEffect(() => {
        setScreen();
    }, []);

    /*设置手机横屏或竖屏*/
    function setScreen() {
        let isMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent);
        let cloudGame = document.getElementById('cloudGame');
        if (cloudGame != null) {
            let isLandScape = window.matchMedia("(orientation:landscape)").matches;
            if (isMobile && !isLandScape) {
                cloudGame.style.position = 'fixed';
                cloudGame.style.width = window.innerHeight + 'px';
                cloudGame.style.height = window.innerWidth + 'px';
                cloudGame.style.top = '50%';
                cloudGame.style.left = '50%';
                let isAndroid = /Android/.test(navigator.userAgent);
                let isiOS = /iPhone|iPad/.test(navigator.userAgent);
                if (isAndroid) {
                    cloudGame.style.transform = 'translate(-50%, -50%) rotate(90deg) scale(1)';
                }

                if (isiOS) {
                    cloudGame.style.transform = 'translate(-50%, -50%) rotate(90deg) scale(1.0025)';
                }
            } else {
                cloudGame.style.width = '100%';
                cloudGame.style.height = '100%';
                cloudGame.style.top = '0';
                cloudGame.style.left = '0';
                cloudGame.style.transform = 'rotate(0deg)';
            }
        }
    }

    /*监听屏幕横屏或属性的变化*/
    useEffect(() => {
        function handleOrientationChange() {
            setScreen();
        }

        window.addEventListener('orientationchange', handleOrientationChange);
        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };

    }, []);

    /*接收子页面发送过来的消息*/
    useEffect(() => {
        function onMessage(event: MessageEvent) {
            if (event.origin !== gameUrl) {
                return;
            }
            try {
                const json = JSON.parse(event.data);
                if (json.message === 'LoadComplete') {
                    const data = {
                        "Action": "Login",
                        "LoginType": loginType,
                        "Account": address
                    };
                    let json = JSON.stringify(data);
                    sendMessage(json);
                } else if (json.message === 'Input') {
                    let mask = document.getElementById('mask') as HTMLElement;
                    mask.style.display = 'block';
                    mask.style.cursor = 'pointer';

                    let input = document.getElementById('input') as HTMLInputElement;
                    input.value = '';
                    input.focus();
                }
            } catch (e) {
                console.error('JSON parse error:', e);
            }
        }

        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, []);

    function Web3ModalProvider({children}: PropsWithChildren) {
        return (
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
            </WagmiProvider>
        )
    }

    const onWalletOrEmailClick = () => {
        let walletButton = document.getElementById('wallet') as HTMLButtonElement;
        let walletFrame = document.getElementById('walletFrame') as HTMLElement;
        let emailFrame = document.getElementById('emailFrame') as HTMLElement;
        if (isWallet) {
            isWallet = false;
            walletButton.textContent = "Change to Wallet";
            walletFrame.style.display = 'none';
            emailFrame.style.display = 'block';
        } else {
            isWallet = true;
            walletButton.textContent = "Change to Email";
            walletFrame.style.display = 'block';
            emailFrame.style.display = 'none';
        }
    }

    const onSetLanguage = () => {
        let languageButton = document.getElementById('language') as HTMLButtonElement;
        if (isEnglish) {
            isEnglish = false;
            languageButton.textContent = "繁體中文";
        } else {
            isEnglish = true;
            languageButton.textContent = "English";
        }
    }

    const onWalletLoginClick = async () => {
        let [account] = await Promise.all([getAccount(config)]);

        if (typeof account === 'undefined' || typeof account.address === 'undefined') {
            alert("Please connect wallet!");
            return;
        }
        setIsShowCloudGame(true);
        showCloudGame();

        loginType = LoginType.Wallet;
        address = account.address;
    }

    function getRandomSixDigitNumber() {
        let random = Math.random() * 1000000;
        let floor = Math.floor(random);
        return floor.toString().padStart(6, '0');
    }


    const onSendClick = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let email = document.getElementById('email') as HTMLInputElement;
        if (!emailRegex.test(email.value)) {
            alert("Please enter the correct email address");
            return
        }
        let random = getRandomSixDigitNumber();
        alert("Please enter code " + random);
        isSend = true;
    }

    const onEmailLoginClick = () => {
        let email = document.getElementById('email') as HTMLInputElement;
        if (email.value === "") {
            alert("Email is empty!");
            return;
        }

        let pwd = document.getElementById('password') as HTMLInputElement;
        if (pwd.value === "") {
            alert("Code is empty!");
            return;
        }

        if (!isSend) {
            alert("Please press Send!");
        }

        setIsShowCloudGame(true);
        showCloudGame();

        loginType = LoginType.Email;
        address = email.value;

        if (isEnglish) {
            language = LanguageType.English;
        } else {
            language = LanguageType.ChineseTraditional;
        }
    };

    const onConfirmClick = () => {
        let input = document.getElementById('input') as HTMLInputElement;
        const data = {
            "Action": "Input",
            "Value": input.value
        };
        let json = JSON.stringify(data);
        sendMessage(json);

        let mask = document.getElementById('mask') as HTMLElement;
        mask.style.display = 'none';
    }

    const DisplayNone: { display: string } = {
        display: "none"
    };

    const DisplayBlock: { display: string } = {
        display: "block"
    }

    return (
        <div className="App">
            <button id="wallet" className="wallet" onClick={onWalletOrEmailClick}>Change to Wallet</button>
            <button id="language" className="language" onClick={onSetLanguage}>English</button>
            <div id="walletFrame" style={DisplayNone}>
                <Web3ModalProvider>
                    <w3m-button/>
                </Web3ModalProvider>
                <button onClick={onWalletLoginClick}>Wallet Login</button>
            </div>

            <div id="emailFrame" style={DisplayBlock}>
                <div>
                    <label>Email:<input id="email" className="email" type="text"
                                        placeholder="Enter your email address"/></label>
                    <button className="send" onClick={onSendClick}>Send</button>
                </div>
                <div>
                    <label>Code:<input id="password" className="password" type="text" placeholder="Enter code"/></label>
                </div>
                <div>
                    <button onClick={onEmailLoginClick}>Email Login</button>
                </div>
            </div>

            <iframe title="Cloud•Hareverse" ref={iframeRef} id="cloudGame" className="cloudGame"
                    style={isShowCloudGame ? DisplayBlock : DisplayNone} src={cloudGameUrl} allowFullScreen/>
            <div id="mask" className="mask">
                <input type="text" id="input" className="input"/>
                <div id="content" className="content">
                    <button id="confirm" className="confirm" onClick={onConfirmClick}>Confirm</button>
                </div>
            </div>
        </div>
    );
}
